.indicator {
  transition: 0.1s ease-in;

  &__open {
    transform: rotate(180deg);
  }
}

.mobileIndicator {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}