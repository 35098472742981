.button {
  padding: 10px var(--spacing-4);
  border-radius: 100px;
  border: 2px solid var(--primary-white);
  font-size: 14px;
  line-height: var(--spacing-5);
}

.atom,
.swap {
  color: var(--primary-white);
  border-color: var(--primary-white);
  padding: var(--spacing-2) var(--spacing-4);
  line-height: 0;
}

.iconGroup {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: var(--spacing-2, 8px);
}
