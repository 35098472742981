.display {
  margin: 0;
  color: var(--primary-white);
  font-family: 'Nostromo-Regular', sans-serif;
  font-style: normal;
  font-weight: 800;
  white-space: break-spaces;

  &.size_1 {
    font-size: 232px;
    line-height: 78%;
    letter-spacing: -23.2px;
  }

  &.size_2 {
    font-size: 100px;
    line-height: 84%;
    letter-spacing: -10px;
  }

  &.size_3 {
    font-size: 76px;
    line-height: 82%;
    letter-spacing: -7.6px;
  }

  &.size_4 {
    font-size: 52px;
    line-height: 82%;
    letter-spacing: -5.2px;
  }

  &.size_5 {
    font-size: 42px;
    line-height: 84%;
    letter-spacing: -4.2px;
  }

  &.size_6 {
    font-size: 32px;
    line-height: 75%;
    letter-spacing: -3.2px;
  }
}

.heading {
  margin: 0;
  color: var(--primary-white);
  font-style: normal;
  white-space: break-spaces;

  &.size_1,
  &.size_2,
  &.size_3,
  &.size_4 {
    font-family: 'Gilroy-SemiBold', sans-serif;
    font-weight: 500;
  }

  &.size_1 {
    font-size: 56px;
    line-height: 64px;
    letter-spacing: -1.68px;
  }

  &.size_2 {
    font-size: 48px;
    line-height: 56px;
    letter-spacing: -1.44px;
  }

  &.size_3 {
    font-size: 32px;
    line-height: 40px;
    letter-spacing: -0.96px;
  }

  &.size_4 {
    font-size: 28px;
    line-height: 36px;
    letter-spacing: -0.84px;
  }

  &.size_5 {
    font-family: 'Gilroy-Medium', sans-serif;
    font-size: 24px;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: -0.72px;
  }
}

.paragraph {
  margin: 0;
  color: var(--primary-white);
  font-style: normal;
  white-space: break-spaces;

  &.Gilroy {
    font-family: 'Gilroy-Regular', sans-serif;
    font-weight: 500;

    &.size_1 {
      font-size: 24px;
      line-height: 30px;
      letter-spacing: -0.72px;
    }

    &.size_2 {
      font-size: 18px;
      line-height: 24px;
    }

    &.size_3 {
      font-size: 16px;
      line-height: 20px;
    }

    &.size_4 {
      font-size: 14px;
      line-height: 16px;
    }
  }

  &.IBMPlexMono {
    font-family: 'IBMPlexMono-Regular';
    font-weight: 500;
    text-transform: uppercase;

    &.size_1 {
      font-size: 18px;
      line-height: 22px;
      letter-spacing: -0.9px;
    }

    &.size_2 {
      font-size: 16px;
      line-height: 20px;
      letter-spacing: -0.48px;
    }

    &.size_3 {
      font-size: 14px;
      line-height: 16px;
      letter-spacing: -0.42px;
    }

    &.size_4 {
      font-size: 12px;
      line-height: 18px;
      letter-spacing: normal;
    }
  }
}

.label {
  margin: 0;
  color: var(--primary-white);
  font-style: normal;
  white-space: break-spaces;

  &.size_1 {
    font-family: 'IBMPlexMono-Regular', sans-serif;
    font-size: 22px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: -0.66px;
  }

  &.size_2 {
    font-family: 'IBMPlexMono-SemiBold', sans-serif;
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
    text-transform: uppercase;
  }

  &.size_3 {
    font-family: 'IBMPlexMono-SemiBold', sans-serif;
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    text-transform: uppercase;
  }

  &.size_4 {
    font-family: 'Gilroy-Regular', sans-serif;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
  }

  &.size_5 {
    font-family: 'IBMPlexMono-Medium', sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    text-transform: uppercase;
  }

  &.size_6 {
    font-family: 'IBMPlexMono-Medium', sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    text-transform: uppercase;
  }

  &.size_7 {
    font-family: 'Gilroy-Regular', sans-serif;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
  }
}
