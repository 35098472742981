.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 25px;
  margin-bottom: 25px;

  a {
    color: #fff;
    padding: 5px;
    cursor: pointer;
    text-decoration: none;
    &:hover {
      text-decoration: none;
    }
  }
  :global(.selected) {
    a {
      text-decoration: underline;
      transform: scale(1.1);
    }
  }
}
