.optionsValue {
  display: flex;
  align-items: center;
  justify-content: space-between;

  gap: 10px;
  font-family: IBM Plex Mono, monospace;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  text-transform: uppercase;
}

.checked {
  display: flex;
  align-items: center;
}

.optionsValueInner {
  display: flex;
  flex-wrap: nowrap;
  gap: 5px;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}