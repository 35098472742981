@import 'src/styles/functions';
@import 'src/styles/mixins';

.header {
  display: flex;
  align-items: flex-start;
  gap: 20px;
  border-bottom: 1px solid var(--neutrals-gray-700);
  margin-bottom: 32px;
}

.categoryContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
  gap: 15px;
  overflow: auto;
}

.categoryList {
  display: flex;
  align-items: center;

  &__item {
    flex-shrink: 0;
  }

  &__btn {
    cursor: pointer;
    color: var(--neutrals-gray-300);
    font-family: Gilroy-Bold, sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 1.333;
    padding: 14px 24px 30px;
    position: relative;

    &:hover {
      color: var(--primary-white);
    }

    &::before {
      content: '';
      width: 100%;
      bottom: 0;
      left: 0;
      position: absolute;
      border-radius: 5px 5px 0px 0px;
      background: var(--primary-white);
      opacity: 0;
      height: 3px;
    }

    &--active {
      &::before {
        opacity: 1;
      }
    }
  }
}

.reloadBtn {
  margin-left: auto;
  display: inline-flex;
  gap: 10px;
  align-items: center;
}
