@import 'styles/variables';
@import 'styles/mixins';

.wrapper {
  display: flex;
  position: relative;
  z-index: 2;
}

.item {
  display: flex;
  align-items: center;
  padding-right: var(--spacing-8);
  gap: var(--spacing-8);
  position: relative;

  &:not(:first-of-type) {
    padding-left: var(--spacing-8);

    &::before {
      content: '';
      width: 1px;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      height: 24px;
      background: rgba(255, 255, 255, 0.2);
    }
  }
}

.logo {
  font-size: 0;
  line-height: 0;
}
