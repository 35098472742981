.otpInput {
  display: flex;
  gap: var(--spacing-2);

  .otpInput__field {
    text-align: center;
    border-radius: 11px;
    border: 1.5px solid var(--neutrals-gray-500);
    background: var(--neutrals-gray-700);
    min-width: 54px;
    width: 54px;
    height: 54px;
    color: var(--primary-white);
    text-align: center;
    font-family: 'Gilroy-SemiBold', sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    letter-spacing: -0.4px;
  }
}
