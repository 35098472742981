@import 'src/styles/functions';
@import 'src/styles/mixins';
.container {
  width: 100%;
  margin-top: 32px;
}

.btnCopy {
  cursor: pointer;
  padding: 0px;
  margin-left: 5px;
  color: var(--neutrals-gray-300);

  @include screen(hover) {
    &:hover {
      color: var(--primary-white);
    }
  }
}

.nfeImage {
  max-width: 64px;
  max-height: 64px;
}

.code {
  white-space: nowrap;
  display: inline-flex;
  align-items: center;
}

.footer {
  margin-top: 25px;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;

  @include screen(630) {
    margin-top: 0;
    justify-content: space-between;
  }
}

.pagination {
  order: 1;

  @include screen(630) {
    order: initial;
  }
}

.status {
  white-space: nowrap;
}
