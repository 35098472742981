@import 'src/styles/functions';
@import 'src/styles/mixins';

.emojiContainer {
  display: flex;
  flex-wrap: wrap;
  margin: 20px -8px 20px;
}

.card.card {
  margin: var(--spacing-2);
  width: calc(100% / 2 - var(--spacing-4));

  @include screen(md) {
    width: calc(100% / 3 - var(--spacing-4));
  }

  @include screen(xl) {
    width: calc(100% / 4 - var(--spacing-4));
  }

  @include screen(large) {
    width: calc(100% / 6 - var(--spacing-4));
  }

  &--large {
    width: 100%;

    @include screen(sm) {
      width: calc(100% / 2 - var(--spacing-4));
    }

    @include screen(md) {
      width: calc(100% / 3 - var(--spacing-4));
    }

    @include screen(large) {
      width: calc(100% / 5 - var(--spacing-4));
    }
  }
}

.detailsCard {
  width: 100%;
  margin: 8px;
}

.header {
  margin-top: 20px;
  margin-bottom: -20px;
}
