.icon {
  cursor: pointer;
  font-family: IBMPlexMono-Regular;
  font-size: var(--spacing-4);
  font-style: normal;
  font-weight: 600;
  text-transform: uppercase;
  transition: all 0.4s ease;
  min-width: 0;

  path {
    fill: var(--primary-white);
  }

  &.disabled {
    path {
      fill: var(--neutrals-gray-500);
    }
  }
}

//! sizes
.spacious {
  padding: 14px;
}

.compact {
  padding: 10px;
}

.small {
  padding: var(--spacing-2);
}

//! appearance
.primary {
  color: var(--primary-black);
  background-image: var(--gradient-p-0);
  background-size: 100%;
  position: relative;
  z-index: 10;

  path {
    fill: var(--primary-black);
  }

  &:before {
    border-radius: inherit;
    background-image: var(--gradient-p-4);
    content: '';
    display: block;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    width: 100%;
    z-index: -10;
    transition: opacity 0.6s;
  }

  &:hover {
    &:before {
      cursor: pointer;
      opacity: 1;
    }
  }

  &.disabled {
    opacity: 0.4;
    pointer-events: none;
  }
}

.secondary {
  background: var(--neutrals-gray-600);

  &:hover {
    background: var(--neutrals-gray-500);
  }

  &.disabled {
    background: var(--neutrals-gray-700);
    pointer-events: none;
  }
}

.tertiary {
  background: var(--primary-black);
  border: 2px solid var(--neutrals-gray-700);

  &:hover {
    border: 2px solid var(--neutrals-gray-600);
    background: var(--neutrals-gray-600);
  }

  &.disabled {
    pointer-events: none;
    border: 2px solid var(--neutrals-gray-600);
  }
}

.danger {
  background: rgba(234, 55, 41, 0.1);
  path {
    fill: var(--system-error);
  }

  &:hover {
    background: var(--system-error);

    path {
      fill: var(--primary-black);
    }
  }

  &.disabled {
    background: var(--neutrals-gray-700);
    pointer-events: none;
  }
}

.warning {
  background: rgba(251, 232, 101, 0.2);
  path {
    fill: var(--system-warning);
  }

  &:hover {
    background: var(--system-warning);

    path {
      fill: var(--primary-black);
    }
  }

  &.disabled {
    background: var(--neutrals-gray-700);
    pointer-events: none;
  }
}

.blue {
  color: var(--primary-white);
  background-image: var(--gradient-wonka-blue);
  background-size: 100%;
  position: relative;
  z-index: 10;

  &:before {
    border-radius: inherit;
    background-image: var(--gradient-wonka-blue-hover);
    content: '';
    display: block;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    width: 100%;
    z-index: -10;
    transition: opacity 0.6s;
  }

  &:hover {
    &:before {
      cursor: pointer;
      opacity: 1;
    }
  }

  &.disabled {
    opacity: 0.4;
    pointer-events: none;
  }
}
