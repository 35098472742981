.container {
  width: 100%;
  min-width: 336px;
  max-width: 336px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: var(--spacing-5);
  padding: var(--spacing-5);
  border-radius: var(--spacing-4);
  color: var(--primary-white);
  font-family: Gilroy-Regular, sans-serif;
  font-size: var(--spacing-12);
  font-style: normal;
  font-weight: 600;
  line-height: var(--spacing-12);
  letter-spacing: -1.44px;
  background: var(
    --gradient-background-linear,
    linear-gradient(139deg, #2b2b2b 0%, #181818 97.89%)
  );

  @media screen and (max-width: 680px) {
    width: 80vw;
    max-width: 460px;
  }

  @media screen and (max-width: 480px) {
    width: 90vw;
  }
}
