@import '../../../../styles/variables';

.user_image {
  width: var(--spacing-12);
  height: var(--spacing-12);
  border-radius: 6.4px;
  background-color: var(--gray);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 35px;
  color: white;
  font-size: 14px;
  font-family: Gilroy-Medium, sans-serif;
  overflow: hidden;

  img {
    object-fit: contain;
    height: 100%;
  }

  &.non_interactive {
    cursor: auto;
  }

  &.small {
    width: var(--spacing-9);
    height: var(--spacing-9);
  }

  @media screen and (max-width: $phone) {
    width: 36px;
    height: 36px;
  }
}
