@import 'styles/variables';
@import 'styles/mixins';

.container {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 1000;
  background-color: var(--primary-black);
}

@keyframes moveUP {
  from {
    transform: translateY(120%);
  }
  to {
    transform: translateY(0%);
  }
}

@keyframes moveDown {
  from {
    transform: translateY(0%);
  }
  to {
    transform: translateY(120%);
  }
}

.animateUp {
  animation: moveUP 0.7s forwards;
}

.animateDown {
  animation: moveDown 0.7s forwards;
}

.inner {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-6);
  max-width: 1808px;
  margin: 0 auto;
  padding: var(--spacing-8) var(--spacing-10);
  background-color: var(--primary-black);

  @include screen(lg) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
}

.text {
  color: #fff;
  font-family: 'Gilroy-Medium', sans-serif;
  font-size: 20px;
  line-height: 1.4;

  @include screen(lg) {
    max-width: 940px;
  }

  a {
    text-decoration-line: underline;
    color: currentColor;
  }
}

.controls {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-2);

  @include screen(md) {
    flex-shrink: 0;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
  }

  @include screen(md) {
    flex-direction: column;
  }

  @include screen(xl) {
    flex-direction: row;
    justify-content: center;
  }
}
