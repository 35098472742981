.nav {
  border: 2px solid var(--neutrals-gray-700);
  border-radius: var(--spacing-3);

  width: fit-content;
  overflow: auto hidden;
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }

  .scrolledContent {
    width: -moz-fit-content;
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: var(--spacing-2);
    gap: var(--spacing-2);
  }

  .nav_item {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: var(--spacing-2);

    &:hover {
      text-decoration: none;
    }

    &.active {
      pointer-events: none;
      background: radial-gradient(
            circle at 100% 100%,
            var(--neutrals-gray-800) 0,
            var(--neutrals-gray-800) 5px,
            transparent 5px
          )
          0% 0%/7px 7px no-repeat,
        radial-gradient(
            circle at 0 100%,
            var(--neutrals-gray-800) 0,
            var(--neutrals-gray-800) 5px,
            transparent 5px
          )
          100% 0%/7px 7px no-repeat,
        radial-gradient(
            circle at 100% 0,
            var(--neutrals-gray-800) 0,
            var(--neutrals-gray-800) 5px,
            transparent 5px
          )
          0% 100%/7px 7px no-repeat,
        radial-gradient(
            circle at 0 0,
            var(--neutrals-gray-800) 0,
            var(--neutrals-gray-800) 5px,
            transparent 5px
          )
          100% 100%/7px 7px no-repeat,
        linear-gradient(var(--neutrals-gray-800), var(--neutrals-gray-800)) 50% 50% /
          calc(100% - 4px) calc(100% - 14px) no-repeat,
        linear-gradient(var(--neutrals-gray-800), var(--neutrals-gray-800)) 50% 50% /
          calc(100% - 14px) calc(100% - 4px) no-repeat,
        var(--gradient-p-0);

      .nav_item__content {
        background: var(
          --gradient-p-0,
          linear-gradient(262deg, #ffbaf3 11.2%, #fff1d4 50.33%, #8bf3fc 90.29%)
        );
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }

    .nav_item__content {
      color: var(--neutrals-gray-300);
      text-wrap: nowrap;
    }
  }

  &.left {
    margin-right: auto;
  }

  &.middle {
    .scrolledContent {
      margin-left: auto;
      margin-right: auto;
    }
  }

  &.right {
    margin-left: auto;
  }

  &.medium {
    height: 56px;

    .nav_item {
      min-width: 125px;
      padding: 10px var(--spacing-3);
    }
  }

  &.large {
    height: 64px;

    .nav_item {
      min-width: 160px;
      padding: 14px 0px;
    }
  }
}

.fullWidth {
  width: 100%;
}
