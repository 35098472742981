@import 'src/styles/functions';
@import 'src/styles/mixins';

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 25px;
  margin-bottom: 25px;

  li {
    cursor: pointer;

    a {
      text-decoration: none;

      &:hover {
        text-decoration: none;
      }
    }

    &:not(:global(.previous), :global(.next)) {
      border-radius: 10px;
      border: 2px solid transparent;
      padding: 7px 6px;
      margin: 0 2px;
      cursor: pointer;

      @include screen(md) {
        border-radius: 14px;
        padding: 9.5px 11px;
      }

      a {
        color: var(--primary-white);
        font-family: 'IBMPlexMono-Medium', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 1.128;
        letter-spacing: -0.8px;
        text-transform: uppercase;
        padding: 0;

        @include screen(md) {
          font-size: 18px;
        }
      }
    }

    &:global(.selected) {
      border: 2px solid var(--primary-white);
    }
  }

  :global(.previous),
  :global(.next) {
    margin-right: 10px;

    a {
      padding: 8px;
      display: block;
      border-radius: 12px;
      background: var(--neutrals-gray-700);

      @include screen(md) {
        padding: 10px;
      }

      &::before {
        content: '';
        width: 24px;
        height: 24px;
        display: block;
        background: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M15.2192 16.0069C15.6968 16.5577 15.6377 17.3907 15.0864 17.8682L13.9692 18.837C13.4185 19.3145 12.585 19.2559 12.1074 18.7051L7.99673 13.9637C7.01924 12.8362 7.019 11.1617 7.99616 10.034L12.1074 5.28912C12.585 4.73834 13.4185 4.67974 13.9692 5.15728L15.0864 6.12603C15.6377 6.60357 15.6968 7.43658 15.2192 7.98736L11.7427 11.9971L15.2192 16.0069Z" fill="white"/></svg>');
        background-size: contain;
      }
    }
  }

  :global(.previous) {
    margin-right: 10px;

    a {
      &::before {
        background: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M15.2192 16.0069C15.6968 16.5577 15.6377 17.3907 15.0864 17.8682L13.9692 18.837C13.4185 19.3145 12.585 19.2559 12.1074 18.7051L7.99673 13.9637C7.01924 12.8362 7.019 11.1617 7.99616 10.034L12.1074 5.28912C12.585 4.73834 13.4185 4.67974 13.9692 5.15728L15.0864 6.12603C15.6377 6.60357 15.6968 7.43658 15.2192 7.98736L11.7427 11.9971L15.2192 16.0069Z" fill="white"/></svg>');
      }
    }
  }

  :global(.previous.disabled) {
    a {
      background: var(--neutrals-gray-700);

      &::before {
        background: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M15.2192 16.0069C15.6968 16.5577 15.6377 17.3907 15.0864 17.8682L13.9692 18.837C13.4185 19.3145 12.585 19.2559 12.1074 18.7051L7.99673 13.9637C7.01924 12.8362 7.019 11.1617 7.99616 10.034L12.1074 5.28912C12.585 4.73834 13.4185 4.67974 13.9692 5.15728L15.0864 6.12603C15.6377 6.60357 15.6968 7.43658 15.2192 7.98736L11.7427 11.9971L15.2192 16.0069Z" fill="%234C4C4C"/></svg>');
      }
    }
  }

  :global(.next) {
    margin-left: 10px;

    a {
      &::before {
        background: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M8.78076 16.0069C8.30322 16.5577 8.3623 17.3907 8.91357 17.8682L10.0308 18.837C10.5815 19.3145 11.415 19.2559 11.8926 18.7051L16.0033 13.9637C16.9808 12.8362 16.981 11.1617 16.0038 10.034L11.8926 5.28912C11.415 4.73834 10.5815 4.67974 10.0308 5.15728L8.91357 6.12603C8.3623 6.60357 8.30322 7.43658 8.78076 7.98736L12.2573 11.9971L8.78076 16.0069Z" fill="white"/></svg>');
      }
    }
  }

  :global(.next.disabled) {
    a {
      background: var(--neutrals-gray-700);

      &::before {
        background: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M8.78076 16.0069C8.30322 16.5577 8.3623 17.3907 8.91357 17.8682L10.0308 18.837C10.5815 19.3145 11.415 19.2559 11.8926 18.7051L16.0033 13.9637C16.9808 12.8362 16.981 11.1617 16.0038 10.034L11.8926 5.28912C11.415 4.73834 10.5815 4.67974 10.0308 5.15728L8.91357 6.12603C8.3623 6.60357 8.30322 7.43658 8.78076 7.98736L12.2573 11.9971L8.78076 16.0069Z" fill="%234C4C4C"/></svg>');
      }
    }
  }
}
