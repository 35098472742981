.wrapper {
  position: fixed;
  min-width: 100vw;
  min-height: 100vh;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;

  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 80px 50px;
    border-radius: 15px;

    @media screen and (max-width: 900px) {
      max-width: 600px;
      padding: 40px 10px;
      width: 100%;
    }
  }

  .title {
    font-size: 40px;
    line-height: 45px;
    text-align: center;
    color: #fff;
    margin-bottom: 68px;
    font-family: Gilroy-SemiBold, sans-serif;

    @media screen and (max-width: 900px) {
      font-size: 26.66px;
      line-height: 30px;
      margin-bottom: 45.33px;
    }

    &__sub {
      font-size: 0.5em;
    }
  }

  a {
    text-decoration: none;
  }
}
