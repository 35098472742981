.button {
  cursor: pointer;
  border-radius: var(--spacing-3);
  font-family: IBMPlexMono-Regular;
  font-size: var(--spacing-4);
  font-style: normal;
  font-weight: 600;
  line-height: var(--spacing-6);
  text-transform: uppercase;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: all;
  position: relative;

  .buttonInner {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;

    &.hide {
      opacity: 0;
    }
  }
}

//! border
.border {
  border-radius: 0;
}

//! sizes
.full {
  width: 100%;
}

.spacious {
  padding: 18px var(--spacing-8);
}

.compact {
  padding: var(--spacing-3) var(--spacing-6);
}

.small {
  font-size: 14px;
  border-radius: var(--spacing-2);
  padding: var(--spacing-2) var(--spacing-4);
}

//! appearance
.primary {
  color: var(--primary-black);
  background-image: var(--gradient-p-0);
  background-size: 100%;
  position: relative;
  z-index: 10;

  &:before {
    border-radius: inherit;
    background-image: var(--gradient-p-4);
    content: '';
    display: block;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    width: 100%;
    z-index: -10;
    transition: opacity 0.6s;
  }

  &:hover {
    &:before {
      cursor: pointer;
      opacity: 1;
    }
  }

  &.disabled {
    opacity: 0.4;
    pointer-events: none;
  }
}

.secondary {
  color: var(--primary-white);
  background: var(--neutrals-gray-700);

  &:hover {
    background: var(--neutrals-gray-500);
  }

  &.disabled {
    color: var(--neutrals-gray-500);
    background: var(--neutrals-gray-700);
    pointer-events: none;
  }
}

.tertiary {
  color: var(--primary-white);
  background-color: #000;
  outline: 2px solid var(--neutrals-gray-700);

  &:hover {
    outline: 2px solid var(--neutrals-gray-600);
    background: var(--neutrals-gray-600);
  }

  &.disabled {
    pointer-events: none;
    outline: 2px solid var(--neutrals-gray-600);
  }
}

.danger {
  background: rgba(234, 55, 41, 0.1);
  color: var(--system-error);

  &:hover {
    background: var(--system-error);
    color: var(--primary-white);
  }

  &.disabled {
    color: var(--neutrals-gray-500);
    background: var(--neutrals-gray-700);
    pointer-events: none;
  }
}

.warning {
  background: rgba(251, 232, 101, 0.1);
  color: var(--system-warning);

  &:hover {
    background: var(--system-warning);
    color: var(--primary-black);
  }

  &.disabled {
    background: var(--neutrals-gray-700);
    color: var(--neutrals-gray-500);
    pointer-events: none;
  }
}

.blue {
  color: var(--primary-white);
  background-image: var(--gradient-wonka-blue);
  background-size: 100%;
  position: relative;
  z-index: 10;

  &:before {
    border-radius: inherit;
    background-image: var(--gradient-wonka-blue-hover);
    content: '';
    display: block;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    width: 100%;
    z-index: -10;
    transition: opacity 0.6s;
  }

  &:hover {
    &:before {
      cursor: pointer;
      opacity: 1;
    }
  }

  &.disabled {
    opacity: 0.4;
    pointer-events: none;
  }
}

.text {
  padding: 0;
  margin: 0;
  background: transparent;
  border: none;
  line-height: 0;
}

.buttonLoader {
  display: flex;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
