@import '../../../styles/variables';

.container {
  width: 100%;
  z-index: 999;
  transition: transform 0.3s ease-in;

  @media screen and (max-width: $sm) {
    padding-top: 0;
  }

  .main_container {
    position: relative;
    width: 100%;
    margin: 0 auto;
    color: var(--gray);
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: center;
    flex-grow: 2;
    padding: 30px 55px 40px 45.5px;

    @media screen and (max-width: $tablet) {
      flex-direction: column-reverse;
      align-items: center;
    }

    &__inner {
      display: flex;
      align-items: center;

      @media screen and (max-width: $tablet) {
        flex-direction: column;
        margin-bottom: 20px;
      }

      @media screen and (min-width: $tablet) {
        gap: 40.5px;
      }
    }

    .nav {
      display: flex;
      font-size: 14px;
      letter-spacing: 0.6px;
      gap: var(--spacing-8);

      @media screen and (max-width: 1100px) {
        font-size: 12px;
      }

      @media screen and (max-width: $tablet) {
        margin-bottom: 20px;
        letter-spacing: 0.5px;
      }

      .nav_item {
        text-decoration: none;

        @media screen and (min-width: $tablet) {
          span {
            transition: all 0.15s ease;
            display: inline-block;

            &:hover {
              transform: scale(1.2);
            }
          }
        }

        color: var(--neutrals-gray-300, #808080);
        font-family: Gilroy-Medium, sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 100%;
        text-transform: lowercase;
      }
    }
  }
}

.items_wrapper {
  align-items: center;
  justify-content: flex-end;
  display: flex;
  gap: 45.5px;

  .link {
    transition: all 0.15s ease;
    width: var(--spacing-4);
  }

  .link:hover {
    transform: scale(1.3);
  }
}

.logo_wrapper {
  text-align: center;
}

.logo {
  width: 62.146px;
  height: 17.599px;
}

.mobile_container {
  display: flex;
  padding: 24px 20px;
  flex-direction: column;

  .top_links {
    display: flex;
    justify-content: space-between;
    padding-bottom: 24px;
    border-bottom: 1px solid var(--neutrals-gray-600, #333);

    &__logo {
      width: 62.146px;
    }

    &__wrapper {
      display: flex;
      align-items: center;
      gap: var(--spacing-4, 16px);
    }

    &__item {
      color: var(--neutrals-gray-300, #808080);
      font-family: Gilroy-Medium, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 100%;
      text-transform: lowercase;
      text-decoration: none;
    }
  }

  .bottom_links {
    padding-top: 24px;
    display: flex;
    justify-content: flex-end;
    gap: var(--spacing-11, 44px);

    &__social {
      width: var(--spacing-4);
    }
  }
}
