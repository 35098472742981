@import 'src/styles/functions';
@import 'src/styles/mixins';

.container {
  box-sizing: border-box;
  position: relative;
  display: flex;
  width: 100%;
}

.section {
  display: flex;
  align-items: center;

  border-radius: var(--spacing-4);
  position: relative;
  width: 100%;

  .avatar_section {
    display: flex;
    align-items: center;
    width: 100%;
    @include screen(maxSm) {
      flex-direction: column;
      width: 100%;
      align-items: flex-start;
    }
  }

  .pzone_section {
    .name {
      word-break: break-word;
      font-size: 56px;
      padding-right: 10px;

      @include screen(maxLarge) {
        font-size: 48px;
      }

      @include screen(maxSm) {
        width: 100%;
        font-size: 32px;
      }
    }
    .button {
      padding: var(--spacing-2) var(--spacing-4);
      line-height: 14px;
      font-size: 12px;
      gap: var(--spacing-2);

      @include screen(maxSm) {
        font-size: 11px;
      }
    }
  }
}

.avatar_wrapper {
  margin-right: 60px;
  &.center {
    left: auto;
  }

  &.small {
    left: 24px;
  }

  @include screen(maxSm) {
    margin-bottom: 12px;
  }
}

.button_edit {
  max-height: 44px;
  min-width: 141px;
  @include screen(maxSm) {
    width: 100%;
    max-height: 44px;
  }
}

.profile_section {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: space-between;
  @include screen(maxSm) {
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
  }
}
