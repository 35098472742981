.wrapper {
  position: fixed;
  min-width: 100vw;
  min-height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(10px);
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;

  a {
    text-decoration: none;
  }

  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 80px 50px;
    border-radius: 15px;

    @media screen and (max-width: 900px) {
      padding: 40px 10px;
      width: 100%;
    }

    .title {
      font-size: 32px;
      line-height: 45px;
      text-align: center;
      color: #fff;
      margin-bottom: 32px;
      font-family: Gilroy-SemiBold, sans-serif;

      @media screen and (max-width: 900px) {
        font-size: 26.66px;
        line-height: 30px;
        margin-bottom: 24px;
      }
    }

    .text {
      font-size: 22px;
      line-height: 1.2px;
      text-align: center;
      color: #fff;
      margin-bottom: 44px;
      font-family: Gilroy-SemiBold, sans-serif;

      @media screen and (max-width: 900px) {
        font-size: 14px;
        line-height: 22px;
        margin-bottom: 34px;
      }
    }

    a {
      text-decoration: none;
    }

    .agreeButton,
    .declineButton {
      height: 80px;
      width: 350px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: black;
      color: #fff;
      border-radius: 15px;
      border: solid 2.5px #6c6c6c;
      font-family: Gilroy-Medium, sans-serif;
      font-size: 30px;
      cursor: pointer;

      @media screen and (max-width: 900px) {
        width: 100%;
        height: 54px;
        font-size: 20px;
        margin-bottom: 50.66px;
      }

      span {
        display: inline-block;
        transition: all 0.15s ease;
      }

      &:hover {
        span {
          transform: scale(1.35);
          color: #fff;
        }
      }
    }

    .agreeButton {
      border-color: #fff;
    }
  }

  .footer {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
  }
}
