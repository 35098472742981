.wrapper {
  color: #fff;
  position: relative;
  max-width: 100%;
  overflow: auto;

  table {
    width: 100%;
    border-spacing: 0;
  }

  :global(.rc-table-cell) {
    text-align: left;
    padding: var(--spacing-8) var(--spacing-6);

    color: var(--primary-white);
    font-family: IBMPlexMono-Medium, sans-serif;
    font-size: 16px;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: -0.48px;
    text-transform: uppercase;
  }

  :global(.rc-table-row) {
    border-bottom: 1px solid #c6c6c6;
  }

  :global(.rc-table-row .rc-table-cell) {
    background: red;
    background: var(--neutrals-gray-700);
  }

  :global(.rc-table-row) {
    &:first-of-type {
      :global(.rc-table-cell) {
        &:first-of-type {
          border-radius: var(--spacing-4) 0px 0px 0px;
        }
        &:last-of-type {
          border-radius: 0px var(--spacing-4) 0px 0px;
        }
      }
    }
    &:last-of-type {
      :global(.rc-table-cell) {
        &:first-of-type {
          border-radius: 0px 0px 0px var(--spacing-4);
        }
        &:last-of-type {
          border-radius: 0px 0px var(--spacing-4) 0px;
        }
      }
    }

    &:not(:last-of-type) {
      :global(.rc-table-cell) {
        border-bottom: 1px solid var(--neutrals-gray-500);
      }
    }
  }

  :global(th.rc-table-cell) {
    white-space: nowrap;
    padding: var(--spacing-4) var(--spacing-6);
    color: var(--neutrals-gray-300);
    font-family: 'IBMPlexMono-SemiBold', sans-serif;
    font-size: 16px;
    line-height: 1.25;
    text-transform: uppercase;
  }
}

.wrapTable {
  &--empty {
    :global(.rc-table) {
      overflow: hidden !important;
    }
    :global(.rc-table-tbody) {
      display: none !important;
    }
  }
}

.loading {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba($color: #000000, $alpha: 0.6);
  z-index: 2;

  &--static {
    position: static;
    text-align: center;
    transform: none;
    height: max(120px, 20vh);
    background: none;
  }
}

.noData {
  color: #fff;
  text-align: center;
  font-family: Gilroy-SemiBold, sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 1.25;
  letter-spacing: -0.96px;
  border-radius: 16px;
  background: var(--neutrals-gray-700);
  min-height: 300px;
  padding: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.card {
  margin-bottom: var(--spacing-4);
  background: var(--neutrals-gray-700);

  border-radius: 8px;

  &__item {
    display: flex;
    align-items: center;
    padding: var(--spacing-8) var(--spacing-6);

    &:not(:last-of-type) {
      border-bottom: 1px solid var(--neutrals-gray-500);
    }

    &_title {
      width: 50%;
      color: var(--primary-white);
      font-family: IBMPlexMono-Medium, sans-serif;
      font-size: 16px;
      font-style: normal;
      line-height: 1.25;
      font-weight: 600;
      letter-spacing: -0.48px;
      text-transform: uppercase;
      color: var(--neutrals-gray-300);
    }

    &_content {
      color: var(--primary-white);
      width: 50%;
      font-family: Gilroy-Medium, sans-serif;
      font-size: 18px;
      line-height: 1.33;
      display: flex;
      justify-content: flex-end;
    }
  }
}

.mobileNoDataContainer {
  height: 200px;
  text-align: center;
}
