@import '../../styles/mixins';

.container {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  gap: 76px;

  .signin_link {
    width: 100%;
  }

  .button {
    height: 80px;
    width: 439px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: black;
    color: #fff;
    border-radius: 15px;
    border: solid 2.5px #6c6c6c;
    font-family: Gilroy-Medium, sans-serif;
    font-size: 30px;
    cursor: pointer;
    transition: all 0.15s ease;
    @media screen and (max-width: 900px) {
      width: 75%;
      height: 74px;
      font-size: 20px;
      margin: auto;
      margin-bottom: 40.66px;
    }

    &:hover {
      @include primaryButton;
      transform: scale(1.06);
    }
  }
}
