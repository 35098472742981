.list {
  color: var(--primary-white, #fff);
  font-family: 'Gilroy-Regular';
  font-size: var(--spacing-8);
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  letter-spacing: -0.32px;
  width: 100%;
  padding-bottom: var(--spacing-4);
  border-bottom: 1px solid var(--neutrals-gray-600);
}
