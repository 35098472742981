.item {
  display: flex;
  border: 2px solid transparent;
  border-radius: var(--spacing-2, 8px);

  &.disabled {
    color: var(--neutrals-gray-300);
    pointer-events: none;
  }

  &.isActive,
  &:hover {
    background: var(--neutrals-gray-600, #333);
  }

  &.isActive {
    pointer-events: none;
  }
}

.textGroup {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: var(--spacing-8);
}

.new {
  border-color: #8bf3fc;

  &.hovered {
    background-image: var(--gradient-p-0);
    color: var(--primary-black);
  }
}

.colored {
  font-family: IBMPlexMono-Regular, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: var(--spacing-4);
  text-transform: uppercase;
  background: var(--gradient-p-0);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  &.hovered {
    -webkit-text-fill-color: var(--primary-black);
  }
}

.link {
  width: 100%;
  color: var(--primary-white);
  font-family: Gilroy-Medium, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: -0.72px;
  text-decoration: none !important;
  padding: var(--spacing-2) var(--spacing-4);
}
