@import 'src/styles/functions';
@import 'src/styles/mixins';

.container {
  overflow: hidden;
  max-width: 1840px;
  width: 100%;
  margin: 0 auto;
  padding-top: 72px;
  padding-right: 20px;
  padding-left: 20px;

  @include screen(md) {
    padding-top: 92px;
  }

  @include screen(large) {
    max-width: 1912px;
    padding-top: 92px;
    padding-left: 56px;
    padding-right: 56px;
  }
}

.content {
  min-height: calc(100vh - 92px);
}

.main {
  flex-grow: 1;
}

.name {
  word-break: break-word;

  @include screen(maxSm) {
    width: 100%;
  }

  @include screen(maxMd) {
    font-size: 48px;
  }
}

.info {
  margin-top: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;
  flex-direction: column;

  @include screen(sm) {
    flex-direction: row;
  }

  &__inner {
    display: flex;
    align-items: center;
    gap: 12px;

    @include screen(maxSm) {
      width: 100%;
    }
  }

  &__edit {
    @include screen(maxSm) {
      width: 100%;
    }
  }
}

.navBar {
  margin-top: 24px;
}
