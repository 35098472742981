.container {
  .wrapper {
    padding: 5px;
    position: relative;
    display: flex;
    align-items: center;
    border-radius: var(--spacing-3);
    background: var(--neutrals-gray-700);
    gap: 1px;

    .button_wrapper {
      padding: 2px;
      width: 63px;
      height: 50px;
      border-radius: var(--spacing-3);
      &.small {
        width: 55px;
        height: 38px;
      }
      &.active {
        background-image: var(--gradient-p-0);
      }

    }

    .selector_button{
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      border-radius: 10px;
      background: var(--neutrals-gray-700);
      cursor: pointer;
    }
  }
}
