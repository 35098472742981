.container {
    position: relative;
    overflow: hidden;

    &.spacious {
        height: 8px;
        width: 79px;
        border-radius: 75px;
    }

    &.compact, &.small {
        width: 60px;
        height: 6px;
        border-radius: 55px;
    }
}

.emptyBar {
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.20);
}

.filledLayer {
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-image: var(--gradient-p-0);
}
