.header {
  width: 100%;
  display: flex;
  align-items: center;
  gap: var(--spacing-4);
  padding-bottom: 14px;
  border-bottom: 1px solid var(--neutrals-gray-600);

  .userName {
    font-weight: 600;
  }
}

.wallet {
  width: 100%;
  border: 2px solid var(--neutrals-gray-500, #4c4c4c);
  border-radius: var(--spacing-2);
  color: var(--primary-white, #fff);
  font-family: 'IBMPlexMono-Regular';
  font-style: normal;
  text-transform: uppercase;
  font-weight: 600;

  &__balance {
    padding: var(--spacing-5) var(--spacing-6);
    display: flex;
    justify-content: space-between;
    border-bottom: 2px solid var(--neutrals-gray-500, #4c4c4c);

    .balanceInfo {
      font-family: 'IBMPlexMono-Regular';
      font-weight: 600;
    }
  }

  .amount {
    font-family: 'IBMPlexMono-Regular';
    font-size: 24px;
    line-height: 18px;
  }

  &__actions {
    display: flex;
    background: var(--neutrals-gray-600);
  }

  .button {
    flex: 1;
    background: var(--neutrals-gray-600);

    &:first-child {
      border-right: 2px solid var(--neutrals-gray-500, #4c4c4c);
    }

    &:hover {
      background-color: var(--neutrals-gray-500);
    }
  }
}

.menu {
  padding: var(--spacing-4);
}

.logout {
  padding: var(--spacing-5) var(--spacing-4);
  color: var(--primary-white, #fff);
  font-family: 'IBMPlexMono-Regular';
  font-size: var(--spacing-4);
  font-style: normal;
  font-weight: 600;
  line-height: var(--spacing-4);
  text-transform: uppercase;
  display: flex;
  justify-content: flex-start;
  width: 100%;
  cursor: pointer;
}
