@import 'styles/variables';
@import 'styles/mixins';

.container {
  display: flex;
  align-items: center;
  gap: var(--spacing-2);

  @include screen(xs) {
    gap: var(--spacing-3);
  }

  &:not(&.last) {
    &:after {
      content: '';
      margin: 0 var(--spacing-2) 0 2px;
      width: 1px;
      height: 16px;
      background: rgba(255, 255, 255, 0.2);

      @include screen(412) {
        margin: 0 var(--spacing-3) 0 4px;
      }

      @include screen(xs) {
        margin: 0 var(--spacing-6) 0 12px;
      }
    }
  }

  &.small {
    &:after {
      margin: 0 var(--spacing-4);
    }
  }
}

.container {
  .label {
    color: #646464;
  }
}
