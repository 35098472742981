.container {
  width: 100%;
  
  .wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;

    &:hover  {
      .field {
        border: 2px solid var(--neutrals-gray-300);

        &:focus {
          color: var(--primary-white);
          outline: none;
          border: 2px solid var(--primary-white);
          animation: bg-hue 2s linear infinite;
        }
      }
    }
  }
}

.field {
  transition: all 0.15s ease;
  height: 60px;
  padding: 0 126px 0 58px;
  border-radius: var(--spacing-3);
  border: 2px solid var(--neutrals-gray-700);
  width: 100%;
  background: var(--neutrals-gray-700);
  font-family: IBMPlexMono-Semibold, sans-serif;
  font-size: 14px;
  font-style: normal;
  text-transform: uppercase;
  color: var(--neutrals-gray-400);

  &.compact {
    height: 48px;
  }
  
  &::placeholder {
    color: var(--neutrals-gray-400);
  }

  &:focus {
    color: var(--primary-white);
    outline: none;
    border: 2px solid var(--primary-white);
    animation: bg-hue 2s linear infinite;
  }
}

.icon, .clear_btn_text {
  color: var(--neutrals-gray-400);
  &.active {
    color: var(--primary-white);
  }
}

.clear_btn {
  padding: 0 var(--spacing-4);
  height: 100%;
  cursor: pointer;
  display: flex;
  justify-content: right;
  align-items: center;
  position: absolute;
  right: 0;
}

.search_icon {
  position: absolute;
  left: 16px;
}

.clear_btn_text {
  padding: 0 var(--spacing-4);
  margin-right: var(--spacing-2);
  font-family: IBMPlexMono-Semibold, sans-serif;
  font-size: 14px;
  font-style: normal;
  text-transform: uppercase;
  color: var(--neutrals-gray-400);
  &.active {
    color: var(--primary-white);
  }
}


