.wrapper {
  margin-bottom: 20px;
  width: 100%;
}

.label {
  font-size: 15px;
  font-weight: 500;
  color: #868f9a;
  margin: 10px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 18px;
}


