@import './variables';

@keyframes appPlay {
  0% {
    background-position: 10% 0%;
  }
  50% {
    background-position: 91% 100%;
  }
  100% {
    background-position: 10% 0%;
  }
}

@mixin primaryButton {
  position: relative;
  text-decoration: none;

  &__text {
    transition: all 0.15s ease;

    display: flex;
    justify-content: center;
    cursor: pointer;
    text-decoration: none;
  }

  &:hover {
    animation: appPlay 4s ease infinite;
    text-decoration: none;
    background: radial-gradient(
      135% 117% at 104.31% 94%,
      #ee99f7 13.45%,
      rgba(173, 112, 255, 0.73) 37.82%,
      rgba(140, 107, 255, 0.65) 55.04%,
      rgba(0, 255, 209, 0.85) 78.15%,
      rgba(178, 161, 243, 0.65)
    );
    background-size: 600% 600%;

    &::after {
      content: '';
      position: absolute;
      z-index: 0;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-image: url('../assets/images/landing/noiseBg.png');
      animation: appPlay 1s linear infinite;
      background-blend-mode: luminosity;
      pointer-events: none;
      filter: brightness(5);
    }
  }
}

@mixin screen($point) {
  @if $point==fullHd {
    @media only screen and (min-width: $fullHd-screen) {
      @content;
    }
  } @else if $point==maxFullHd {
    @media only screen and (max-width: ($fullHd-screen - 0.1px)) {
      @content;
    }
  }
  @if $point==large {
    @media only screen and (min-width: $large-screen) {
      @content;
    }
  } @else if $point==maxLarge {
    @media only screen and (max-width: ($large-screen - 0.1px)) {
      @content;
    }
  } @else if $point==xl {
    @media only screen and (min-width: $laptop) {
      @content;
    }
  } @else if $point==maxXl {
    @media only screen and (max-width: ($laptop - 0.1px)) {
      @content;
    }
  } @else if $point==lg {
    @media only screen and (min-width: $tablet-lg) {
      @content;
    }
  } @else if $point==maxLg {
    @media only screen and (max-width: ($tablet-lg - 0.1px)) {
      @content;
    }
  } @else if $point==md {
    @media only screen and (min-width: $tablet) {
      @content;
    }
  } @else if $point==maxMd {
    @media only screen and (max-width: ($tablet - 0.1px)) {
      @content;
    }
  } @else if $point==sm {
    @media only screen and (min-width: $sm) {
      @content;
    }
  } @else if $point==maxSm {
    @media only screen and (max-width: ($sm - 0.1px)) {
      @content;
    }
  } @else if $point==xs {
    @media only screen and (min-width: $phone) {
      @content;
    }
  } @else if $point==maxXs {
    @media only screen and (max-width: ($phone - 0.1px)) {
      @content;
    }
  } @else if $point==hover {
    @media (hover: hover) and (pointer: fine) {
      @content;
    }
  } @else {
    @media only screen and (min-width: $point + 'px') {
      @content;
    }
  }
}
