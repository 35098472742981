@import 'src/styles/mixins';

.container {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.title {
  color: var(--primary-white);
  font-size: 32px;
  font-weight: 600;
  line-height: 1.25;
  letter-spacing: -0.96px;
  font-family: Gilroy-SemiBold, sans-serif;
  margin-bottom: 0;
  text-wrap: balance;
}

.text {
  color: var(--neutrals-gray-300);
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.33;
  font-family: Gilroy-Medium, sans-serif;
  margin-top: var(--spacing-2);
  margin-bottom: 0;
  text-wrap: balance;
}

.button {
  margin-top: var(--spacing-8);
}

.banner {
  margin-bottom: -50px;

  @include screen(maxSm) {
    width: 180%;
  }
}

.img {
  max-width: 100%;
}
