.container {
  width: 100%;
  display: flex;
  gap: 12px;
  .search {
    flex-grow: 1;
  }
  .select {
    width: 100%;
    max-width: 164px;
  }
  .control {
    width: 100%;
    max-width: 136px;
  }
}