.wrap_loader {
  color: #fff;
  text-align: center;

  &--center {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
  }

  &--absolute-center {
    left: 50%;
    top: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
  }
}

.wrapper {
  position: relative;

  &::before {
    content: '';
    display: none;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #000;
    opacity: 0.6;
  }

  &--active {
    &::before {
      display: block;
    }
  }
}

.gradientIcon {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.gradientIcon .label {
  background: var(--gradient-p-0);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.gradientIcon img {
  animation: gradientRotate 1s linear infinite;
}

@keyframes gradientRotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
