@import 'src/styles/functions';
@import 'src/styles/mixins';

.wrapper {
  width: 575px;
  max-width: 575px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: var(--spacing-4) !important;
  padding-top: 0px !important;

  .header {
    align-self: flex-end;
    padding-top: var(--spacing-4);
    padding-bottom: var(--spacing-4);
    margin-bottom: var(--spacing-2);
  }

  .close_btn {
    background-color: transparent;
    margin-left: auto;

    &:hover {
      cursor: pointer;
      background-color: transparent;
    }
  }

  .title {
    text-align: center;
    font-family: 'Gilroy-SemiBold', sans-serif;
    font-size: var(--spacing-8);
    font-style: normal;
    font-weight: 600;
    line-height: 40px;
    letter-spacing: -0.96px;
    margin-bottom: var(--spacing-2);

    @media screen and (max-width: 576px) {
      font-size: var(--spacing-6);
    }
  }

  .content {
    margin-bottom: var(--spacing-8);
    max-width: 415px;
  }
}
