@import 'src/styles/functions';
@import 'src/styles/mixins';

.playaNFTsWrapper {
  width: 100%;
  max-width: 720px;
  min-height: 706px;
  position: relative;
  padding: 24px !important;
  display: flex;
  flex-direction: column;

  .header {
    display: flex;
    justify-content: space-between;
    padding-bottom: var(--spacing-8);
  }

  .navBar {
    border-color: var(--Neutrals-Gray-600, #333);
    margin-bottom: var(--spacing-4);
  }

  .closeBtn {
    background-color: transparent;
  }

  .close {
    &:hover {
      cursor: pointer;
      svg {
        path {
          fill: #fff;
        }
      }
    }
  }

  .list {
    flex: 1;
    overflow-y: auto;
    max-height: 430px;
    display: flex;
    flex-wrap: wrap;
    padding-bottom: var(--spacing-8);

    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    grid-gap: 1rem;

    -ms-overflow-style: none;
    scrollbar-width: none;
    position: relative;
  }

  .list::-webkit-scrollbar {
    display: none;
  }

  .footer {
    display: flex;
    justify-content: flex-end;
    gap: var(--spacing-4);
    background-color: #222;
    color: #fff;
    padding-top: 24px;
    width: 100%;
  }

  .cancel_button {
    background-color: var(--neutrals-gray-600);
  }
}

.details {
  display: flex;
}

.card {
  width: 100%;
}

.single_card {
  width: 45%;
}

.card_size {
  margin: 8px;
  width: calc(100% / 2 - var(--spacing-4));

  @include screen(sm) {
    width: calc(100% / 3 - var(--spacing-4));
  }

  @include screen(md) {
    width: calc(100% / 4 - var(--spacing-4));
  }

  @include screen(lg) {
    width: calc(100% / 5 - var(--spacing-4));
  }

  @include screen(xl) {
    width: calc(100% / 6 - var(--spacing-4));
  }

  @include screen(large) {
    width: calc(100% / 7 - var(--spacing-4));
  }

  @include screen(fullHd) {
    width: calc(100% / 8 - var(--spacing-4));
  }

  &--large {
    width: 100%;

    @include screen(sm) {
      width: calc(100% / 2 - var(--spacing-4));
    }

    @include screen(md) {
      width: calc(100% / 3 - var(--spacing-4));
    }

    @include screen(lg) {
      width: calc(100% / 4 - var(--spacing-4));
    }

    @include screen(xl) {
      width: calc(100% / 5 - var(--spacing-4));
    }

    @include screen(large) {
      width: calc(100% / 6 - var(--spacing-4));
    }

    @include screen(fullHd) {
      width: calc(100% / 7 - var(--spacing-4));
    }
  }
}

.loader {
  z-index: 10;
  height: 100%;
  width: 100%;
  background: rgba($color: #000000, $alpha: 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
}
