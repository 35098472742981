@import '../../../../../styles/variables';
.wrapper {
  position: relative;
  z-index: 2;
  display: flex;
  align-items: center;
  gap: 40px;
  align-items: center;

  @media screen and (max-width: $phone) {
    gap: 24px;
  }

  .profile_image_wrapper {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    .user_image {
      width: var(--spacing-12);
      height: var(--spacing-12);
      border-radius: 6.4px;
      background-color: var(--gray);
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      line-height: 35px;
      color: white;
      font-size: 14px;
      font-family: Gilroy-Medium, sans-serif;
      overflow: hidden;

      @media screen and (max-width: $phone) {
        width: 36px;
        height: 36px;
      }
    }
    .profile_dropdown_wrapper {
      position: absolute;
      top: 73px;
      right: -12px;

      @media screen and (max-width: 480px) {
        position: fixed;
        left: calc(100vw - 95%);
        width: 100%;
      }
    }
  }
}

.wrapper_auth {
  display: flex;
  color: var(--gray);
  align-items: center;
  font-size: 16px;
  font-family: BallingerMono-Medium, sans-serif;

  @media screen and (min-width: 1900px) {
    font-size: 16px;
  }

  @media screen and (min-width: 4000px) {
    font-size: 32px;
  }

  &__link {
    font-family: IBM Plex Mono, monospace;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: var(--primary-white);

    &:hover {
      background-image: var(--gradient-p-0);
      background-size: 100%;
      background-clip: text;
      color: transparent;
      cursor: pointer;
      text-decoration: none;
    }
  }

  &__item {
    display: flex;
    align-items: center;
    padding-left: var(--spacing-6);
    position: relative;

    &:not(:first-of-type) {
      &::before {
        content: '';
        width: 1px;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        height: 24px;
        background: rgba(255, 255, 255, 0.2);
      }
    }

    &:not(:last-of-type) {
      padding-right: var(--spacing-6);
    }
  }
}

.login_link {
  color: var(--primary-white);
  font-family: 'IBMPlexMono-Regular', monospace;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 1px;
  display: inline-block;
  border-radius: 12px;
  padding: 2px;
  background: var(--gradient-p-0);

  transition: 0.2s;

  span {
    display: inline-block;
    background: var(--primary-black);
    padding: 10px 28px;
    border-radius: 10px;
    transition: 0.2s;
  }

  &:hover {
    text-decoration: none;
    color: var(--primary-black);

    span {
      background: transparent;
    }
  }
}

.app_link {
  display: flex;
  align-items: center;
  gap: 24px;
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }

  &__gradient {
    background: linear-gradient(262deg, #ffbaf3 11.2%, #fff1d4 50.33%, #8bf3fc 90.29%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: 'IBMPlexMono-SemiBold', sans-serif;
    font-size: 12px;
    font-style: normal;
    line-height: 100%;
    text-transform: uppercase;
    margin-top: 6px;
    margin-bottom: 0;

    a {
      text-decoration: none;

      &:hover {
        text-decoration: none;
      }
    }
  }
  &__text {
    color: #fff;
    font-family: 'Gilroy-SemiBold', sans-serif;
    font-size: 16.5px;
    font-style: normal;
    line-height: 100%;
    margin-bottom: 0;
  }
}

.keyBtn {
  color: white;
  width: 100px;
  padding: 2px;
  border: 1px solid #fff;
  border-radius: 12px;
  padding: 4px;
  cursor: pointer;
  transition: all 0.2s;

  &:hover {
    color: greenyellow;
    border-color: greenyellow;
  }
}
