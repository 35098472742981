@import 'src/styles/functions';
@import 'src/styles/mixins';

.container {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  border-radius: var(--spacing-8);
  border: 1.5px solid rgba(255, 255, 255, 0.2);
  position: relative;
  width: 100%;
  min-height: 764px;
  padding: var(--spacing-6);
  gap: var(--spacing-6);

  &.small {
    border-radius: var(--spacing-4);
    padding: var(--spacing-2);
    flex-direction: column;
    align-items: flex-end;
    gap: var(--spacing-4);
  }
}

.preview {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1 0 0;
  align-self: stretch;
  position: relative;
  justify-content: center;
  width: 50%;
  border-radius: var(--spacing-6);
  border: 1.5px solid rgba(255, 255, 255, 0.2);
  padding: var(--spacing-6);
  gap: var(--spacing-6);
  border-radius: var(--radius-large);

  > div {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  img {
    object-fit: contain;
  }

  &.small {
    padding: var(--spacing-2);
    padding-top: 50px;
    gap: 18px;
    border-radius: var(--radius-normal);
    width: 100%;

    img {
      max-width: 80%;
    }
  }
}

.playControls {
  display: flex;
  align-items: center;
  gap: var(--spacing-4);
}

.pauseEllipse {
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;

  &.small {
    width: 30px;
    height: 30px;
  }
}

.description {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;
  height: 100%;
  width: 50%;

  &.small {
    flex: 0;
    gap: var(--spacing-6);
    max-width: 100%;
    width: 100%;
  }
}

.descriptionInner {
  width: 100%;
  padding: 0 184px;

  &.spacious {
    padding: 0 var(--spacing-8);
  }

  &.compact {
    padding: 0 var(--spacing-12);
  }

  &.small {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-3);
    padding: 0 var(--spacing-2);
  }
}

.title {
  margin-bottom: var(--spacing-8);

  &.small {
    margin-bottom: 0;
  }

  &__text.title__text {
    word-break: break-word;
    font-size: 34px;
    font-family: PPMonumentExtended-Black, sans-serif;
    text-transform: uppercase;
    letter-spacing: -1.7px;
    line-height: 40px;

    @include screen(md) {
      font-size: 24px;
      line-height: 28px;
      letter-spacing: -1.2px;
    }
  }
}

.descriptionInner {
  .price {
    font-size: 20px;
    color: var(--primary-white);
    margin-bottom: var(--spacing-2);

    &.small {
      font-size: 20px;
      line-height: 24px;
      margin-bottom: -4px;
    }
  }

  .token_group {
    width: fit-content;
    width: -moz-fit-content;
    border-radius: 4px;
    border: 1px solid rgba(255, 255, 255, 0.15);
    padding: var(--spacing-2);
    margin-bottom: var(--spacing-8);
    color: rgba(255, 255, 255, 0.5);

    &.small {
      margin-bottom: 0;
    }
  }
}

.topRightIcon {
  position: absolute;
  top: var(--spacing-6);
  right: var(--spacing-6);
  z-index: 9;

  &.small {
  }
}

.itemsContainer {
  width: 100%;
  display: flex;
  align-items: center;

  &.small {
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    padding: 16px 0 4px 0;
  }
}

.buttonsContainer {
  margin-bottom: var(--spacing-10);
  display: flex;
  gap: var(--spacing-4);

  &.small {
    margin-bottom: var(--spacing-2);
    gap: var(--spacing-2);
    order: 1;
  }
}

.loading {
  filter: blur(8px);
}

.minted_label {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: var(--spacing-4);
  margin-bottom: var(--spacing-8);

  &__inner {
    display: flex;
    align-items: center;
    gap: 4px;
  }

  &.small {
    margin-bottom: 0;
  }
}

.tag,
.owned {
  color: var(--primary-white);
  font-family: 'IBMPlexMono-SemiBold', sans-serif;
  padding: 8px;
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;
  border-radius: 4px;
  background: var(--glow-gradient-linear-angle);
}
.owned {
  span {
    background: linear-gradient(262deg, #ffbaf3 11.2%, #fff1d4 50.33%, #8bf3fc 90.29%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

.token_id_text {
  color: var(--neutrals-gray-200);
  font-family: 'IBMPlexSans-Medium', monospace;
  font-size: 11px;
  font-style: normal;
  line-height: 16px;
}
