@import 'styles/variables';

.formControl {
  position: relative;
  display: inline-block;
  margin-top: var(--spacing-1);
  padding-bottom: 28px;
  margin-bottom: var(--spacing-1);

  &__fullWidth {
    width: 100%;
  }

  &__noGutters {
    margin: 0;
  }
}

.label {
  color: #fff;
  font-family: IBMPlexMono-Regular, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 1;
  text-transform: uppercase;
  margin-bottom: var(--spacing-4);

  &__disabled {
    color: var(--neutrals-gray-500);
  }
}

.error {
  color: var(--system-error);
  position: absolute;
  bottom: 0px;
  left: 0px;

  font-family: IBMPlexMono-Regular, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 1.4;
  text-transform: uppercase;
  transition: 0.25s;
  opacity: 0;
  visibility: hidden;

  &_show {
    opacity: 1;
    visibility: visible;
  }

  &__disabled {
    color: var(--neutrals-gray-500);
  }
}

.helperText {
  position: absolute;
  bottom: 0px;
  left: 0px;
  font-family: IBMPlexMono-Regular, sans-serif;
  font-size: 14px;
  transition: 0.25s;
  opacity: 0;
  visibility: hidden;
  color: var(--neutrals-gray-500);

  &__show {
    opacity: 1;
    visibility: visible;
  }
  &__disabled {
    border-color: var(--neutrals-gray-600);
  }
}
