.updateCoverWrapper {
  width: 100%;
  max-width: 516px;

  .title {
    padding-bottom: var(--spacing-8);
  }

  .modal_paragraph {
    color: var(--neutrals-gray-300);
    padding-bottom: var(--spacing-8);
  }

  .footer {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-4);
  }

  .cancel_button {
    background-color: var(--neutrals-gray-600);
  }
}
