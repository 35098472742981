@import 'src/styles/functions';
@import 'src/styles/mixins';

.container {
  max-width: 1750px;
  margin: 0 auto;
  padding: 30px 0;

  @include screen(lg) {
    padding: 30px;
  }
}

.title {
  font-family: PPMonumentExtended-SemiBold, sans-serif;
  font-size: 26px;
  font-stretch: normal;
  line-height: 0.81;
  letter-spacing: normal;
  color: #fff;

  @include screen(large) {
    margin-bottom: 21.6px;
  }
}

.btnCopy {
  cursor: pointer;
  padding: 0px;
  margin-left: 5px;
  color: #adadad;

  @include screen(hover) {
    &:hover {
      color: #fff;
    }
  }
}

.categoryContainer {
  overflow: auto;
}

.categoryList {
  display: flex;
  align-items: center;

  &__item {
    padding: 20px;
  }

  &__btn {
    cursor: pointer;
    color: #fff;
    border-bottom: 1px solid transparent;

    &--active {
      border-bottom-color: #fff;
    }
  }
}

.nfeImage {
  max-width: 64px;
  max-height: 64px;
}

.wrap_backBtn {
  margin-bottom: 20px;

  a {
    color: #fff;
  }
}
