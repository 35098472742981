:root {
  --font: #ffffff;
  --gray: #5b5b5b;
  --gray-second: #575757;
  --gray-third: #838383;
  --blue: #1d9bf0;
  --error: #e60909;

  // UI kit

  --spacing-1: 4px;
  --spacing-2: 8px;
  --spacing-3: 12px;
  --spacing-4: 16px;
  --spacing-5: 20px;
  --spacing-6: 24px;
  --spacing-7: 28px;
  --spacing-8: 32px;
  --spacing-9: 36px;
  --spacing-10: 40px;
  --spacing-11: 44px;
  --spacing-12: 48px;
  --spacing-14: 60px;
  --spacing-15: 64px;

  --radius-large: 16px;
  --radius-normal: 12px;
  --radius-medium: 8px;
  --radius-small: 4px;

  --primary-white: #fff;
  --primary-black: #000;

  --neutrals-gray-100: #f5f5f5;
  --neutrals-gray-200: #b2b2b2;
  --neutrals-gray-300: #808080;
  --neutrals-gray-400: #646464;
  --neutrals-gray-500: #4c4c4c;
  --neutrals-gray-600: #333;
  --neutrals-gray-700: #222;
  --neutrals-gray-800: #1c1c1d;

  --accent-green: #cbfd50;
  --accent-orange: #ea9829;
  --accent-sad-purple: #c3c7df;
  --accent-midnight-green: #1a2f36;
  --accent-turquoise: #8bf3fc;
  --accent-purple: #9759ff;

  --gradient-s-0: linear-gradient(
    225deg,
    #bae2db 24.6%,
    #f59ff7 44.61%,
    #bd9afe 59.9%,
    #b0c2eb 79.18%
  );
  --gradient-p-0: linear-gradient(262deg, #ffbaf3 11.2%, #fff1d4 50.33%, #8bf3fc 90.29%);
  --gradient-p-1: linear-gradient(346deg, #ffbaf3 -1.36%, #fff1d4 46.28%, #8bf3fc 94.93%);
  --gradient-p-2: linear-gradient(58deg, #ffbaf3 -16.34%, #fff1d4 39.14%, #8bf3fc 94.61%);
  --gradient-p-3: linear-gradient(83deg, #ffbaf3 -19.1%, #fff1d4 33.61%, #8bf3fc 88.56%);
  --gradient-p-4: linear-gradient(90deg, #ffbaf3 0.29%, #fff1d4 49.4%, #8bf3fc 99.54%);
  --gradient-p-5: linear-gradient(73deg, #8bf3fc 5.24%, #fff1d4 52.62%, #ffbaf3 100%);
  --gradient-3: linear-gradient(180deg, rgba(151, 89, 255, 0) 9.22%, #8bf3fc 100.69%);
  --gradient-s-1: linear-gradient(71deg, #8bf3fc 0%, #d8b9fd 100%);
  --gradient-s-2: linear-gradient(71deg, #d494da 0%, #fcc79b 100%);
  --gradient-s-3: linear-gradient(71deg, #c4a5eb 0%, #ffbaf3 100%);
  --gradient-2: linear-gradient(180deg, #8ffa01 0%, #fbcb00 51.04%, #fa2ef7 100%);
  --gradient-4: linear-gradient(90deg, #fcbd35 -0.02%, #f84ad8 45.35%, #fcbb36 97.85%);
  --gradient-1: linear-gradient(248deg, #fb41ff 0%, #f5d212 49.7%, #98f82d 100%);
  --gradient-wonka-red: linear-gradient(262deg, #ff1006 11.2%, #f195a3 46.32%, #536b84 90.29%);
  --gradient-wonka-blue: linear-gradient(262deg, #0057e7 11.2%, #002b84 46.32%, #00fefe 90.29%);
  --gradient-wonka-blue-hover: linear-gradient(
    262deg,
    #00fefe 11.2%,
    #002b84 51.98%,
    #0057e7 90.29%
  );
  --glow-gradient-glow-rose-teal: linear-gradient(
    209deg,
    #8bf3fc 0%,
    rgba(139, 243, 252, 0.3) 51.07%,
    #fa7bd4 100%
  );
  --glow-gradient-glow-neutral: linear-gradient(
    209deg,
    #fff 0%,
    rgba(139, 243, 252, 0) 51.07%,
    #fff 100%
  );
  --glow-gradient-glow-blue-teal: linear-gradient(
    209deg,
    rgba(1, 88, 232, 0.4) 0%,
    rgba(139, 243, 252, 0) 51.07%,
    rgba(1, 255, 255, 0.4) 100%
  );
  --stroke-gradient-white-transparent: linear-gradient(
    209deg,
    rgba(255, 255, 255, 0.1) 0%,
    rgba(139, 243, 252, 0) 51.07%,
    rgba(255, 255, 255, 0.1) 100%
  );
  --glow-gradient-glow-white: linear-gradient(
    199deg,
    #fff 1.01%,
    rgba(255, 255, 255, 0) 27.73%,
    #fff 50.92%,
    rgba(255, 255, 255, 0) 80.16%,
    #fff 97.8%
  );
  --gradient-linear: linear-gradient(139deg, #474747, #2d2d2d);
  --gradient-background-linear: linear-gradient(139deg, #2b2b2b 0%, #181818 97.89%);
  --glow-gradient-linear-angle: linear-gradient(
    209deg,
    rgba(139, 243, 252, 0.25) 0%,
    rgba(139, 243, 252, 0.08) 51.07%,
    rgba(139, 243, 252, 0.18) 100%
  );
  --gradient-wonka-reveal: linear-gradient(171.78deg, #020808, #113333, #000202);

  --system-error: #ea3729;
  --system-warning: #fbe865;
  --system-success: #75fb4d;
}

body {
  font: 16px/1.2 'Gilroy-Regular', 'Gilroy-Medium', 'Arial', 'Helvetica', sans-serif;
  min-width: 320px;
  color: #000000;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #000000;
}

canvas {
  image-rendering: pixelated;
}

.Toastify__toast-container--top-right {
  z-index: 99999999;
}

#modal-root {
  position: relative;
}

.ui.segment {
  background: black;
  box-shadow: none;
  border: none;
  padding: 0;
}

.ui.segment .ui.basic.button,
.ui.segment .ui.basic.buttons .button {
  background: transparent none !important;
  color: #fff !important;
  font-weight: 400;
  border-radius: 0.28571429rem;
  text-transform: none;
  text-shadow: none !important;
  box-shadow: none;
}

.ui.segment .ui.basic.button:hover,
.ui.segment .ui.basic.buttons .button:hover {
  background: #181818 !important;
  color: #fff !important;
  box-shadow: none;
}

.ui.segment .clndr-cell {
  background-color: #ffffff24;
}

.ui.segment .clndr-cell:hover {
  opacity: 1;
  border-color: #000000;
  background-color: rgba(245, 245, 247, 0.6);
}

.ui.segment .clndr-cell-disabled:hover {
  pointer-events: none;
}

//Nostromo

@font-face {
  font-family: 'Nostromo-Regular';
  src: url('./assets/fonts/Nostromo/NostromoRegular-Black.otf') format('opentype');
  font-style: normal;
  font-display: swap;
}

//Gilroy

@font-face {
  font-family: 'Gilroy-Bold';
  src: url('./assets/fonts/Gilroy/Gilroy-Bold.otf') format('opentype');
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gilroy-Light';
  src: url('./assets/fonts/Gilroy/Gilroy-Light.otf') format('opentype');
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gilroy-Medium';
  src: url('./assets/fonts/Gilroy/Gilroy-Medium.otf') format('opentype');
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gilroy-MediumItalic';
  src: url('./assets/fonts/Gilroy/Gilroy-MediumItalic.otf') format('opentype');
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gilroy-Regular';
  src: url('./assets/fonts/Gilroy/Gilroy-Regular.otf') format('opentype');
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gilroy-SemiBold';
  src: url('./assets/fonts/Gilroy/Gilroy-SemiBold.otf') format('opentype');
  font-style: normal;
  font-display: swap;
}

//PPMonumentExtended

@font-face {
  font-family: 'PPMonumentExtended-Black';
  src: url('~assets/fonts/PPMonumentExtended/PPMonumentExtended-Black.otf') format('opentype'),
    url('~assets/fonts/PPMonumentExtended/PPMonumentExtended-Black.woff2') format('woff2'),
    url('~assets/fonts/PPMonumentExtended/PPMonumentExtended-Black.ttf') format('truetype');
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'PPMonumentExtended-BlackItalic';
  src: url('~assets/fonts/PPMonumentExtended/PPMonumentExtended-BlackItalic.otf') format('opentype'),
    url('~assets/fonts/PPMonumentExtended/PPMonumentExtended-BlackItalic.woff2') format('woff2'),
    url('~assets/fonts/PPMonumentExtended/PPMonumentExtended-BlackItalic.ttf') format('truetype');
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'PPMonumentExtended-Semibold';
  src: url('~assets/fonts/PPMonumentExtended/PPMonumentExtended-Semibold.otf') format('opentype'),
    url('~assets/fonts/PPMonumentExtended/PPMonumentExtended-Semibold.woff2') format('woff2'),
    url('~assets/fonts/PPMonumentExtended/PPMonumentExtended-Semibold.ttf') format('truetype');
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'PPMonumentExtended-Bold';
  src: url('~assets/fonts/PPMonumentExtended/PPMonumentExtended-Bold.otf') format('opentype'),
    url('~assets/fonts/PPMonumentExtended/PPMonumentExtended-Bold.woff2') format('woff2'),
    url('~assets/fonts/PPMonumentExtended/PPMonumentExtended-Bold.ttf') format('truetype');
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'PPMonumentExtended-Medium';
  src: url('~assets/fonts/PPMonumentExtended/PPMonumentExtended-Medium.otf') format('opentype'),
    url('~assets/fonts/PPMonumentExtended/PPMonumentExtended-Medium.woff2') format('woff2'),
    url('~assets/fonts/PPMonumentExtended/PPMonumentExtended-Medium.ttf') format('truetype');
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'PPMonumentExtended-Regular';
  src: url('~assets/fonts/PPMonumentExtended/PPMonumentExtended-Regular.otf') format('opentype'),
    url('~assets/fonts/PPMonumentExtended/PPMonumentExtended-Regular.woff2') format('woff2'),
    url('~assets/fonts/PPMonumentExtended/PPMonumentExtended-Regular.ttf') format('truetype');
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'PPMonumentExtended-SemiBold';
  src: url('~assets/fonts/PPMonumentExtended/PPMonumentExtended-Semibold.otf') format('opentype'),
    url('~assets/fonts/PPMonumentExtended/PPMonumentExtended-Semibold.woff2') format('woff2'),
    url('~assets/fonts/PPMonumentExtended/PPMonumentExtended-Semibold.ttf') format('truetype');
  font-style: normal;
  font-display: swap;
}

//IBMPlexMono

@font-face {
  font-family: 'IBMPlexSans-Medium';
  src: url('./assets/fonts/IBMPlexSans/IBMPlexSans-Medium.ttf') format('opentype');
  font-style: normal;
  font-display: swap;
}

//IBMPlexMono

@font-face {
  font-family: 'IBMPlexMono-Light';
  src: url('./assets/fonts/IBMPlexMono/IBMPlexMono-Light.ttf') format('opentype');
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'IBMPlexMono-Medium';
  src: url('./assets/fonts/IBMPlexMono/IBMPlexMono-Medium.ttf') format('opentype');
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'IBMPlexMono-Regular';
  src: url('./assets/fonts/IBMPlexMono/IBMPlexMono-Regular.ttf') format('opentype');
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'IBMPlexMono-SemiBold';
  src: url('./assets/fonts/IBMPlexMono/IBMPlexMono-SemiBold.ttf') format('opentype');
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'IBMPlexMono-SemiBoldItalic';
  src: url('./assets/fonts/IBMPlexMono/IBMPlexMono-SemiBoldItalic.ttf') format('opentype');
  font-style: normal;
  font-display: swap;
}

//BallingerMono

@font-face {
  font-family: 'BallingerMono';
  src: url('~assets/fonts/BallingerMono/BallingerMono-Black.woff2') format('woff2');
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'BallingerMono-Regular';
  src: url('~assets/fonts/BallingerMono/BallingerMono-Regular.woff2') format('woff2');
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'BallingerMono-Medium';
  src: url('~assets/fonts/BallingerMono/BallingerMono-Medium.woff2') format('woff2');
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'BallingerMono-Light';
  src: url('~assets/fonts/BallingerMono/BallingerMono-Light.woff2') format('woff2');
  font-style: normal;
  font-display: swap;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: bold;
  margin: 0 0 0.5em;
  color: #fff;
}

h1 {
  font-size: 100px;
}

h2 {
  font-size: 60px;
}

h3 {
  font-size: 45px;
}

h4 {
  font-size: 30px;
}

h5 {
  font-size: 20px;
}

a {
  color: #117ea0;
}

a:hover {
  text-decoration: underline;
}

html {
  box-sizing: border-box;
  font-size: 16px;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

*:focus {
  outline: none;
  box-shadow: none;
}

p {
  margin: 0 0 0.4em;
}

ol,
ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

html {
  line-height: 1.15; /* 1 */
  -webkit-text-size-adjust: 100%; /* 2 */
}

/**
 * Remove the margin in all browsers.
 */

body {
  margin: 0;
}

li {
  list-style: none;
}

/* Grouping content
   ========================================================================== */

/**
 * 1. Add the correct box sizing in Firefox.
 * 2. Show the overflow in Edge and IE.
 */

hr {
  box-sizing: content-box; /* 1 */
  height: 0; /* 1 */
  overflow: visible; /* 2 */
}

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */

pre {
  font-family: monospace; /* 1 */
  font-size: 1em; /* 2 */
}

/**
 * Remove the gray background on active links in IE 10.
 */

a {
  background-color: transparent;
  text-decoration: none;
}

/**
 * 1. Remove the bottom border in Chrome 57-
 * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
 */

abbr[title] {
  border-bottom: none; /* 1 */
  text-decoration: underline; /* 2 */
  text-decoration: underline dotted; /* 2 */
}

/**
 * Add the correct font weight in Chrome, Edge, and Safari.
 */

b,
strong {
  font-weight: bold;
}

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */

code,
kbd,
samp {
  font-family: monospace; /* 1 */
  font-size: 1em; /* 2 */
}

/**
 * Add the correct font size in all browsers.
 */

small {
  font-size: 80%;
}

/**
 * Prevent `sub` and `sup` elements from affecting the line height in
 * all browsers.
 */

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

/* Embedded content
   ========================================================================== */

/**
 * Remove the border on images inside links in IE 10.
 */

img {
  border-style: none;
  vertical-align: top;
  max-width: 100%;
}

/* Forms
   ========================================================================== */

/**
 * 1. Change the font styles in all browsers.
 * 2. Remove the margin in Firefox and Safari.
 */

button,
input,
optgroup,
select,
textarea {
  font-family: inherit; /* 1 */
  font-size: 100%; /* 1 */
  line-height: 1.15; /* 1 */
  margin: 0; /* 2 */
  border: none;
  background: transparent;
}

/**
 * Show the overflow in IE.
 * 1. Show the overflow in Edge.
 */

button,
input {
  /* 1 */
  overflow: visible;
}

/**
 * Remove the inheritance of text transform in Edge, Firefox, and IE.
 * 1. Remove the inheritance of text transform in Firefox.
 */

button,
select {
  /* 1 */
  text-transform: none;
}

/**
 * Correct the inability to style clickable types in iOS and Safari.
 */

button,
[type='button'],
[type='reset'],
[type='submit'] {
  -webkit-appearance: button;
}

/**
 * Remove the inner border and padding in Firefox.
 */

button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

/**
 * Restore the focus styles unset by the previous rule.
 */

button:-moz-focusring,
[type='button']:-moz-focusring,
[type='reset']:-moz-focusring,
[type='submit']:-moz-focusring {
  outline: 1px dotted ButtonText;
}

/**
 * Correct the padding in Firefox.
 */

fieldset {
  padding: 0.35em 0.75em 0.625em;
}

/**
 * 1. Correct the text wrapping in Edge and IE.
 * 2. Correct the color inheritance from `fieldset` elements in IE.
 * 3. Remove the padding so developers are not caught out when they zero out
 *    `fieldset` elements in all browsers.
 */

legend {
  box-sizing: border-box; /* 1 */
  color: inherit; /* 2 */
  display: table; /* 1 */
  max-width: 100%; /* 1 */
  padding: 0; /* 3 */
  white-space: normal; /* 1 */
}

/**
 * Add the correct vertical alignment in Chrome, Firefox, and Opera.
 */

progress {
  vertical-align: baseline;
}

/**
 * Remove the default vertical scrollbar in IE 10+.
 */

textarea {
  overflow: auto;
}

/**
 * 1. Add the correct box sizing in IE 10.
 * 2. Remove the padding in IE 10.
 */

[type='checkbox'],
[type='radio'] {
  box-sizing: border-box; /* 1 */
  padding: 0; /* 2 */
}

/**
 * Correct the cursor style of increment and decrement buttons in Chrome.
 */

[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  height: auto;
}

/**
 * 1. Correct the odd appearance in Chrome and Safari.
 * 2. Correct the outline style in Safari.
 */

[type='search'] {
  -webkit-appearance: textfield; /* 1 */
  outline-offset: -2px; /* 2 */
}

/**
 * Remove the inner padding in Chrome and Safari on macOS.
 */

[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
 * 1. Correct the inability to style clickable types in iOS and Safari.
 * 2. Change font properties to `inherit` in Safari.
 */

::-webkit-file-upload-button {
  -webkit-appearance: button; /* 1 */
  font: inherit; /* 2 */
}

/* Interactive
   ========================================================================== */

/*
 * Add the correct display in Edge, IE 10+, and Firefox.
 */

details {
  display: block;
}

/*
 * Add the correct display in all browsers.
 */

summary {
  display: list-item;
}

/* Misc
   ========================================================================== */

/**
 * Add the correct display in IE 10+.
 */

template {
  display: none;
}

/**
 * Add the correct display in IE 10.
 */

[hidden] {
  display: none;
}

.ui.basic.scrolling.modal.transition.visible.active {
  background: #fff;
  opacity: 1;
  color: initial;
  margin: 0 auto;
  padding: 50px 15px;
  border-radius: 15px;
}

.ui.basic.scrolling.modal.transition.visible.active .close.icon {
  color: #000;
}

.react-tooltip {
  padding: 8px !important;
  border-radius: 8px !important;
  z-index: 999 !important;
}
