.pill {
  padding: 10px var(--spacing-4);
  font-family: 'IBMPlexMono-SemiBold', sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.42;
  text-transform: uppercase;
  border-radius: 100px;
  border: 2px solid transparent;
  display: inline-block;
}

.success {
  color: var(--system-success);
  border-color: var(--system-success);
}
.error {
  color: var(--system-error);
  border-color: var(--system-error);
}

.warning {
  color: var(--system-warning);
  border-color: rgba(251, 232, 101, 0.2);
}
