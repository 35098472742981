.container {
  max-width: 426px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--spacing-5);
  padding: var(--spacing-5);
  border-radius: var(--spacing-4);
  color: var(--primary-white);
  font-family: 'Gilroy-Regular';
  font-size: var(--spacing-12);
  font-style: normal;
  font-weight: 600;
  line-height: var(--spacing-12);
  letter-spacing: -1.44px;
  background: var(
    --gradient-background-linear,
    linear-gradient(139deg, #2b2b2b 0%, #181818 97.89%)
  );
}

.header {
  width: 100%;
  padding-bottom: var(--spacing-5);
  border-bottom: 1px solid var(--neutrals-gray-600);
  display: flex;
  gap: 60px;
  align-items: center;
}

.button {
  color: #ffffff;
  font-size: 14px;
  cursor: pointer;
  border-radius: var(--spacing-3);
  background: var(--neutrals-gray-700, #222);
  padding: 10px;
}

.title {
  font-family: 'IBMPlexMono-Regular';
  font-size: 14px;
  line-height: var(--spacing-5);
  margin-bottom: var(--spacing-2);
  text-transform: uppercase;
}

.code {
  font-size: var(--spacing-3);
  padding: var(--spacing-5);
  border-radius: var(--spacing-3);
  border: 2px solid var(--neutrals-gray-500);
  background: var(--neutrals-gray-600);
  color: var(--neutrals-gray-300);
  font-family: 'Gilroy-Regular';
  font-size: var(--spacing-5);
  font-style: normal;
  font-weight: 600;
  line-height: var(--spacing-6);
}

.description {
  color: var(--neutrals-gray-200, #b2b2b2);
  font-size: 14px;
}

.iconBg {
  background: var(--neutrals-gray-700);
}

.input {
  margin-bottom: 0;
  padding-bottom: 0;
}
