@import '../../../styles/variables';

.container {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1001;
  transition: transform 0.3s ease-in;
  border-radius: var(--spacing-3);

  &:after {
    position: relative;
    content: '';
  }

  &.hidden {
    transform: translateY(-170%);
    transition: transform 0.3s ease-in;
  }

  &.bg {
    backdrop-filter: saturate(180%) blur(20px);
  }

  .main_container {
    position: relative;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-content: space-between;
    align-items: center;
    flex-grow: 2;
    padding: 14px 32px 14px 32px;
    min-height: 64px;

    @media screen and (min-width: $tablet) {
      min-height: 77px;
    }

    @media screen and (max-width: $tablet-lg) {
      min-height: 77px;
      grid-template-columns: repeat(2, 1fr);
    }

    .logo {
      font-family: Nostromo-Regular, sans-serif;
      font-size: 32px;
      font-weight: 800;
      line-height: 32px;
      letter-spacing: -0.1em;
      text-align: center;
      color: var(--primary-white);

      &:hover {
        text-decoration: none;
        color: var(--primary-white);
      }
    }

    @media screen and (max-width: 1200px) {
      padding: 18px 26px;
    }
  }
}

.rightGroupMobile {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.leftGroupMobile {
  display: flex;
  gap: 40px;
  align-items: center;

  @media screen and (max-width: $phone) {
    gap: 24px;
  }
}
