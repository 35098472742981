@import 'src/styles/mixins';
.container {
  position: relative;

  .camera_button_wrapper {
    position: absolute;

    &.spacious {
      bottom: 1%;
      right: 8%;

      &.conner {
        bottom: 3%;
        right: 4%;
      }
    }

    &.compact {
      bottom: 2%;
      right: 8%;

      &.conner {
        bottom: 0;
        right: 2%;
      }
    }

    &.small {
      bottom: 0%;
      right: 8%;

      &.conner {
        bottom: 0;
        right: 2%;
      }
    }
  }
}

.modalWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 13;
  pointer-events: none;
  position: fixed;
  top: 0;
  left: 0;
  background: #252424cc;
  height: 100%;
  width: 100vw;
}

.crop {
  position: fixed;
  z-index: 14;
  left: 50%;
  top: 80px;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #3d3d3d;
  padding: 50px 40px 20px;
  max-width: 430px;
  border-radius: 10px;

  &__content {
    max-height: min(70vh, 700px);
    overflow: auto;
    max-height: 340px;
    overflow: auto;
    overflow-y: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;
  }

  &__close {
    width: 30px;
    height: 30px;
    position: absolute;
    top: 13px;
    right: 16px;
    color: #716e6e;
    z-index: 2;
    cursor: pointer;

    &:hover {
      svg {
        path {
          fill: var(--primary-white);
        }
      }
    }
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    text-align: center;
    width: 100%;
  }

  &__save {
    width: 173px;
    height: 72px;
    border-radius: 12.5px;
    color: #878787;
    background-color: #181818;
    font-size: 20px;
    font-family: Gilroy-Medium, sans-serif;
    cursor: pointer;
    transition: all 0.15s ease;
    margin: 2px;

    &:hover {
      color: var(--primary-white);
      scale: 1.05;
    }
  }
}

.cropper {
  width: 266px;
  line-height: 0;
  font-size: 0;
  padding: 2px;

  :global(.ReactCrop__child-wrapper) {
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 0;
    font-size: 0;
  }
}

.loading {
  color: var(--primary-white);
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 3;
  background: rgba($color: #1c1c1d, $alpha: 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: customClamp(40);
}

.wrapDelete {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
  left: 0;
  top: 0;
  &:hover {
    .deleteContainer {
      display: flex;
    }
  }
}

.deleteContainer {
  display: none;
  position: absolute;
  width: 80%;
  height: 80%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  align-items: center;
  justify-content: center;
}

.delete {
  color: var(--primary-white);
  width: 40%;
  cursor: pointer;
}

.default_image_wrapper {
  width: 185px;

  &.large {
    width: 100%;
  }

  &.medium {
    width: 150px;
  }

  &.small {
    width: 120px;
  }

  .border {
    width: 100%;
    height: auto;
  }
}

.user_image_wrapper {
  position: relative;
  background: var(--neutrals-gray-600);
  border-radius: 16px;
  padding: 6px;

  &__with_border {
    background-image: url('../../../assets/images/avatar/bg_grey.png');
    padding: 30px 35px;
    background-color: transparent;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__no_img {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: var(--spacing-8);
    color: var(--primary-white);
  }

  .image {
    height: 100%;
    border-radius: 12px;
    object-fit: contain;
  }

  .hexagon {
    position: absolute;
  }

  &.spacious {
    width: 210px;
    height: 210px;

    .hexagon {
      width: calc(100% - 22px);
      height: calc(100% - 22px);
    }
  }

  &.compact {
    width: 168px;
    height: 168px;

    .hexagon {
      width: calc(100% - 18px);
      height: calc(100% - 18px);
    }
  }

  &.small {
    width: 126px;
    height: 126px;

    .hexagon {
      width: calc(100% - 14px);
      height: calc(100% - 14px);
    }
  }
}

.popup {
  position: absolute;
  top: 40px;
  left: -14px;
  color: var(--primary-white);
  width: 180px;
  padding: var(--Spacing-2, 8px);
  border-radius: var(--Spacing-2, 8px);
  background: var(--Neutrals-Gray-600, #333);
  box-shadow: 0px 2.767px 2.214px 0px rgba(0, 0, 0, 0.02), 0px 6.65px 5.32px 0px rgba(0, 0, 0, 0.03),
    0px 12.522px 10.017px 0px rgba(0, 0, 0, 0.04), 0px 22.336px 17.869px 0px rgba(0, 0, 0, 0.04),
    0px 41.778px 33.422px 0px rgba(0, 0, 0, 0.05), 0px 100px 80px 0px rgba(0, 0, 0, 0.07);

  &__item {
    display: flex;
    align-items: center;
    padding: var(--Spacing-2, 8px) var(--Spacing-3, 12px);
    border-radius: var(--Spacing-2, 8px);
    gap: var(--Spacing-2, 8px);

    &:hover {
      background: var(--Neutrals-Gray-500, #4c4c4c);
    }

    p {
      margin-bottom: 0;
    }
  }
}
