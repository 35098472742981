.container {
  box-sizing: border-box;
  background: #000;
  cursor: pointer;
  flex-shrink: 0;

  &.spacious {
    padding: 7px;
    border-radius: var(--spacing-3);
  }

  &.compact {
    padding: 4px;
    border-radius: var(--spacing-2);
  }

  &.small {
    padding: 4px;
    border-radius: var(--spacing-2);
  }
}

.camera_button_wrapper {
  background: linear-gradient(90deg, #fad4dd 0%, #ffedd9 50.31%, #a8edee 100%);
  display: flex;
  justify-content: center;
  align-items: center;

  &.spacious {
    padding: var(--spacing-2) var(--spacing-3);
    border-radius: var(--spacing-2);
  }

  &.compact {
    padding: var(--spacing-2) var(--spacing-3);
    border-radius: var(--spacing-1);
  }

  &.small {
    padding: var(--spacing-1) var(--spacing-2);
    border-radius: var(--spacing-1);
  }

  img {
    width: 20px;
    height: auto;

    &.small {
      width: 16px;
    }
  }
}
