@import 'styles/variables';
@import 'styles/mixins';

.card {
  position: relative;
  border-radius: 16px;
  border: 2px solid rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(36.89795684814453px);
  transition: border-color 0.3s;
  padding: 8px;
  color: #bbbfc5;
  background: #0c0b0c;

  &__pointer {
    cursor: pointer;
  }

  &.active {
    border-color: currentColor;
  }
}

.inner {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.picture {
  flex-grow: 1;
  border-radius: 8px;
  aspect-ratio: 1/1;
  position: relative;

  &__inner {
    width: 100%;
    overflow: hidden;
    height: 100%;
    position: absolute;
    padding: 10px;

    &_wrap {
      position: relative;
      transition: 0.3s;
      width: 100%;
      height: 100%;
    }
  }

  img {
    min-height: 50px;
    max-width: 92%;
    max-height: 92%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    &:first-of-type {
      z-index: 1;
      transform: translate(-50%, -50%) scale(1.07);
      filter: blur(10px);
    }

    &:last-of-type {
      z-index: 2;
    }
  }
}

.details {
  border-radius: 8px;
  background: linear-gradient(138.66deg, rgba(255, 255, 255, 0.2) 0%, rgba(0, 0, 0, 0.2) 97.89%);
  backdrop-filter: blur(27.6734676361084px);
  padding: 12px;
  transition: 0.3s;
  margin-top: auto;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &_info {
      display: flex;
      gap: 4px;
      align-items: center;
    }
  }

  &__owned {
    border-radius: 4px;
    padding: 5px 8px;
    background: linear-gradient(
      208.91deg,
      rgba(139, 243, 252, 0.25) 0%,
      rgba(139, 243, 252, 0.075) 51.07%,
      rgba(139, 243, 252, 0.1825) 100%
    );

    span {
      display: block;
      font-family: IBMPlexMono-SemiBold, sans-serif;
      font-size: 12px;
      font-style: normal;
      line-height: 1.5;
      text-transform: uppercase;
      background: linear-gradient(262deg, #ffbaf3 11.2%, #fff1d4 50.33%, #8bf3fc 90.29%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    &.small {
      border-radius: 2px;

      span {
        font-size: 10px;
        line-height: 1;
      }
    }
  }

  &__id {
    color: --neutrals-gray-200;
    font-family: IBMPlexMono-Medium, sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    text-transform: uppercase;
  }

  &__arrow {
    &_icon {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
      padding: 5px 4px;
      background: linear-gradient(
        208.91deg,
        rgba(139, 243, 252, 0.25) 0%,
        rgba(139, 243, 252, 0.075) 51.07%,
        rgba(139, 243, 252, 0.1825) 100%
      );

      &.small {
        border-radius: 2px;

        svg {
          width: 11px;
          height: 10px;
        }
      }
    }
  }
  &__content {
    margin-top: 8px;
  }
  &__name {
    color: #fff;
    font-family: Gilroy-SemiBold, sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 1.11;
    margin-top: 4px;
    margin-bottom: 4px;

    &.small {
      font-size: 14px;
    }
  }

  &__price {
    display: flex;
    align-items: center;

    &_value {
      color: var(--neutrals-gray-200);
      font-family: IBM Plex Mono, monospace;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 1.14;
      text-transform: uppercase;

      &.small {
        font-size: 12px;
      }
    }

    &_type {
      margin-left: auto;
      padding: 6.918px;
      border-radius: 4.612px;
      border: 1.153px solid rgba(255, 255, 255, 0.15);

      color: rgba(255, 255, 255, 0.5);
      leading-trim: both;
      text-edge: cap;
      font-family: IBM Plex Mono, monospace;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: 1;
      text-transform: uppercase;

      &.small {
        font-size: 9px;
      }
    }
  }
}

.buyContent {
  opacity: 0;
  touch-action: none;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 0;
  transition: 0.3s;
  transform: translateY(10%);
}

@mixin Hover {
  border-color: currentColor;

  .picture__inner_wrap {
    transform: scale(1.05) translateY(7%);
  }

  .buyContent {
    z-index: 2;
    transform: translateY(0);
    opacity: 1;
  }

  .details {
    z-index: 1;
    opacity: 0;
    transform: translateY(10%);
    touch-action: none;
  }
}

.cardBuy {
  overflow: hidden;
  position: relative;
  cursor: pointer;

  &:not(.cardBuy__owned) {
    @media (hover: hover) {
      &:hover {
        @include Hover;
      }
    }

    &.isHover {
      @include Hover;
    }
  }

  @media (hover: hover) {
    &:hover {
      border-color: currentColor;
    }
  }

  &.isHover {
    border-color: currentColor;
  }

  .details {
    position: relative;
    z-index: 2;
  }
}

.cardSft {
  .inner {
    padding-bottom: 80px;

    &.small {
      padding-bottom: 90px;
    }
  }

  .details__content {
    margin: 0;

    &_inner {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      gap: 10px;
    }
  }

  &__btn {
    width: 100%;
    margin-top: 4px;
    max-height: 0;
    overflow: hidden;
    transition: 0.3s;

    & > * {
      width: 100%;
    }
  }

  .picture {
    transition: 0.3s;
  }

  .details__header {
    transition: 0.3s;
    max-height: 0;
    overflow: hidden;

    .details__arrow {
      padding-bottom: 8px;
    }
  }

  &__details {
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 2;
    width: 100%;
  }

  .picture__inner_wrap {
    transition: transform 2s ease;

    img {
      transition: 0.3s;
    }
  }

  &:hover,
  &.active {
    .picture__inner_wrap {
      animation: bounce 2s ease;
    }

    .details__header {
      max-height: 40px;
    }
    .cardSft__btn {
      max-height: 60px;
    }
  }
}

@keyframes bounce {
  0% {
    transform: translateY(0%);
  }
  10% {
    transform: translateY(-15%);
  }
  20% {
    transform: translateY(0%);
  }
  25% {
    transform: translateY(-7%);
  }
  27% {
    transform: translateY(0%);
  }
  29% {
    transform: translateY(-3%);
  }
  30% {
    transform: translateY(0%);
  }
  100% {
    transform: translateY(0);
  }
}
