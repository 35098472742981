.header {
  width: 100%;
  padding-bottom: var(--spacing-5);
  border-bottom: 1px solid var(--neutrals-gray-600);
  display: flex;
  gap: 60px;
}

.iconBg {
  background: var(--neutrals-gray-700);
}

.input {
  margin-bottom: 0;
  padding-bottom: 0;
}

.balance {
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: var(--spacing-6);
  padding-bottom: var(--spacing-5);
  border-bottom: 1px solid var(--neutrals-gray-600);

  &__item {
    display: flex;
    justify-content: space-between;
    margin-bottom: var(--spacing-4);

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__price {
    font-size: 18px;
    line-height: var(--spacing-6);
  }
}

.description {
  color: var(--neutrals-gray-200, #b2b2b2);
  font-size: 14px;
  font-weight: 500;
  line-height: var(--spacing-4);

  &__list {
    margin-bottom: var(--spacing-2);

    &:last-child {
      margin-bottom: 0;
    }
  }
}
