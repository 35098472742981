@import 'src/styles/functions';
@import 'src/styles/mixins';
.container {
  display: inline-flex;
  align-items: center;
  gap: 10px;
}

.total,
.text {
  font-family: IBMPlexMono-SemiBold, sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0px;
  text-transform: uppercase;
  color: var(--neutrals-gray-300);
  white-space: nowrap;
}

.select {
  margin-bottom: 0 !important;
}

.option {
  white-space: nowrap;
  div {
    display: flex;
    align-items: center;
    gap: 8px;
  }
}
