@import 'styles/mixins';
@import 'styles/variables';

.input {
  min-height: 28px;
  border: none;
  color: var(--primary-white);
  flex: 1 0 0;

  font-family: Gilroy-Regular, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;

  &:placeholder {
    color: currentColor;
  }

  &__disabled {
    cursor: not-allowed;
    color: var(--neutrals-gray-500);
  }

  &_textarea {
    resize: none;
  }

  &__resize {
    resize: initial;
  }
}

.wrapInput {
  align-items: center;
  display: flex;
  padding: 10px 6px;
  min-height: 60px;
  color: var(--neutrals-gray-300);
  border: 2px solid var(--neutrals-gray-500);
  background: var(--neutrals-gray-600);
  transition: 0.25s;
  gap: 5px;
  background: var(--neutrals-gray-600);
  border-radius: 12px;

  @include screen(412) {
    padding: 10px 12px;
  }

  @include screen(xs) {
    padding: 10px 16px;
  }

  &:hover {
    border-color: var(--neutrals-gray-300);
  }

  &__focused.wrapInput__focused {
    color: var(--primary-white);
    border-color: var(--primary-white);
  }
  &__error {
    border-color: var(--system-error) !important;
    background: rgba(234, 55, 41, 0.1) !important;
  }

  &__disabled {
    border-color: var(--neutrals-gray-600) !important;
    background: var(--neutrals-gray-700) !important;
    cursor: not-allowed;
  }

  .togglePassword {
    cursor: pointer;
    display: flex;
    position: absolute;
    right: 16px;

    svg {
      pointer-events: none;
    }
  }
}
